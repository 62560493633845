import { BanqueItemPlans, ListPlansObtenu } from "../../../types/components/Hotel/PlansChantierType";
import { Chantier }                         from "./Chantier";
import React                                from "react";
import SvgIcone                             from "../../../components/generality/SvgIcone";
import { useECContext }                     from "../../../types/Context/Encyclopedie/Chantier/EncyChantierContext";
import { ChantierPrototypeDTO }             from "../../../types/models/chantierPrototype.dto";
import { ChantiersDTO }                     from "../../../types/models/chantiers.dto";
import { useTranslation }                   from "react-i18next";
import { useNormalizedSearch }              from "../../../services/hook/useNormalizedSearch";


interface LigneChantierProps {
    chantier: ChantierPrototypeDTO,
    niveau: number,
    generic: boolean,
    banque?: BanqueItemPlans[],
    listPlansVille?: ListPlansObtenu[],
    listChantiersVille?: ChantiersDTO[],
    listAvancement?: {
        pa: number | null
    }[],
    gestion_aff: {
        aff_construit: boolean,
        aff_reparer: boolean,
        aff_construction: boolean,
        aff_obtenu: boolean,
        aff_bloque: boolean,
    },
    reductionPA?: number,
    filtre_ressource?: string,
    indicateurChoix?: boolean,
    maskTemp?: boolean,
}

export default function LigneChantier({
    chantier,
    niveau,
    generic,
    banque = [],
    listPlansVille = [],
    listChantiersVille = [],
    listAvancement = [],
    gestion_aff,
    reductionPA = 0,
    filtre_ressource = "",
    indicateurChoix = false,
    maskTemp = false,
}: LigneChantierProps) {
    const { t } = useTranslation();
    
    const { listChantierChoisi, setListChantierChoisi } = useECContext();
    const { normalizeSearch } = useNormalizedSearch();
    
    const id: number = chantier.id;
    
    
    const chantierClass = new Chantier({
        chantier    : chantier,
        planObtenu  : listPlansVille[id] !== undefined,
        construction: listChantiersVille[id] !== undefined && !listChantiersVille[id].detruit,
        avancement  : listAvancement[id],
        reparation  : listChantiersVille[id],
    });
    
    const plan_string: string = chantierClass.getPlansString();
    
    if (niveau > 7) {
        return null;
    }
    
    const classLigneChantier: string = chantierClass.getClassChantier();
    
    // gestion de l'affichage
    let affichage_ligne: boolean = true;
    switch (classLigneChantier) {
        case "":
            affichage_ligne = true;
            break;
        case "chEndom":
            affichage_ligne = gestion_aff.aff_reparer;
            break;
        case "chCons":
            affichage_ligne = gestion_aff.aff_construit;
            break;
        case "chEnCons":
            affichage_ligne = gestion_aff.aff_construction;
            break;
        case "chBlock":
            affichage_ligne = gestion_aff.aff_bloque;
            break;
        case "plObte":
            affichage_ligne = gestion_aff.aff_obtenu;
            break;
    }
    
    // Calcul de l'affichage du chantier si on a un filtre et que le chantier ne contient pas le nom de l'item recherché
    if (affichage_ligne && filtre_ressource !== "" && chantierClass.ressources !== null) {
        affichage_ligne = chantierClass.ressources.filter((ressource) => {
            return normalizeSearch(filtre_ressource, t(ressource.item.nom, { ns: "items" }));
        }).length > 0;
    }
    
    if (maskTemp && chantierClass.temp) {
        affichage_ligne = false;
    }
    
    const coutPAChantier = Math.round(chantierClass.pa * (100 - reductionPA) / 100);
    const reductionPAChantier = Math.round(chantierClass.pa * reductionPA / 100);
    
    
    return <React.Fragment key={"regroupement_chantier_" + chantierClass.id}>
        {affichage_ligne && <tr className={"ligneChantier " + ((generic) ? "" : classLigneChantier)} id={"listChantier_" + chantierClass.id} key={"ligne_chantier_" + chantierClass.id}>
            <td className={"chantier_nom " + ((niveau > 0) ? ("lv-" + Math.min(niveau, 6)) : "root")}>
				<span className={"infoBulle infoBulleHelpCursor"}>
                    {indicateurChoix && <span><input type={"checkbox"} checked={listChantierChoisi.includes(chantierClass.id)} onChange={(event) => {
                        const list_chantier_choixMod = [...listChantierChoisi];
                        if (event.target.checked) {
                            list_chantier_choixMod.push(chantierClass.id);
                        } else {
                            list_chantier_choixMod.splice(list_chantier_choixMod.indexOf(chantierClass.id), 1);
                        }
                        setListChantierChoisi(list_chantier_choixMod);
                    }} /></span>}
                    <span className={"chantier_name_espacement"}><SvgIcone icone={chantierClass.icon} />{t(chantierClass.nom, { ns: "chantiers" })}</span>
					<span className={"infoChantier"}>{t(chantierClass.description, { ns: "chantiers" })}</span>
				</span>
            </td>
            <td className="chantier_plan"><SvgIcone icone={plan_string} /></td>
            <td className="chantier_temp">{chantierClass.temp && <SvgIcone icone={"tag_1"} />}</td>
            <td className="chantier_repa">{chantierClass.pv > 0 && !chantierClass.indes && <i className="fas fa-check color-check"></i>}</td>
            <td className="chantier_pa">
                <div className={"alignIcone_chantier"}><span>{coutPAChantier}</span><SvgIcone icone={"small_pa"} /></div>
            </td>
            {!generic && <td className="chantier_pa paRestant">{(chantierClass.avancement.pa < chantierClass.pa) ? (chantierClass.avancement.pa - reductionPAChantier) : ""}</td>}
            <td className="chantier_pv">{chantierClass.pv === 0 ? "" : chantierClass.pv}</td>
            {!generic && <td className="chantier_pv paRestant">{(chantierClass.reparation.pv < chantierClass.pv) ? chantierClass.reparation.pv : ""}</td>}
            <td className="chantier_def">{chantierClass.def === 0 ? "" : <div className={"alignIcone_chantier"}><span>{chantierClass.def}</span><SvgIcone icone={"small_def"} /></div>}</td>
            <td className="chantier_eau">{chantierClass.water === 0 ? "" : <div className={"alignIcone_chantier"}><span>+{chantierClass.water}</span><SvgIcone icone={"small_water"} /></div>}</td>
            <td className="chantier_ressources">
                <div>
                    {chantierClass.ressources !== null && chantierClass.ressources.sort((ress_a, ress_b) => ress_a.item.id_mh < ress_b.item.id_mh ? -1 : 1)
                        .map((ressource) => {
                                let classSpan: string;
                                let nombreItems: number;
                                if (generic) {
                                    classSpan = "ressourceChantier";
                                } else {
                                    // Recherche de l'item en banque
                                    const itemBanque = banque[ressource.item.id];
                                    if (itemBanque === undefined) {
                                        nombreItems = 0;
                                    } else {
                                        nombreItems = itemBanque.nbrItem;
                                    }
                                    
                                    classSpan = ((nombreItems >= ressource.nombre) ? "ressOK" : "ressKO") + " ressourceChantierNotGeneric";
                                }
                                
                                return <span className={classSpan} key={"chantier_" + chantier.id + "_item_" + ressource.item.id}>
                                    {!generic && <span className={"item_banque"}>{nombreItems} / </span>}
                                    {ressource.nombre}
                                    <span className="infoBulle">
                                        <SvgIcone icone={ressource.item.icon} />
                                        <span className={"info"}>{t(ressource.item.nom, { ns: "items" })}</span>
                                    </span>
                                </span>;
                            },
                        )}
                </div>
            </td>
        </tr>}
        {chantierClass.children.length !== 0 &&
            chantierClass.children.sort((a: ChantierPrototypeDTO, b: ChantierPrototypeDTO) => a.order_by_listing < b.order_by_listing ? -1 : 1)
                .map((chantierFille: ChantierPrototypeDTO) => {
                    if (chantierFille.actif) {
                        return <LigneChantier chantier={chantierFille} niveau={niveau + 1} generic={generic}
                                              banque={banque} listChantiersVille={listChantiersVille}
                                              listPlansVille={listPlansVille}
                                              key={"ligneChantier_cat_" + chantierFille.id}
                                              listAvancement={listAvancement} gestion_aff={gestion_aff} reductionPA={reductionPA}
                                              filtre_ressource={filtre_ressource} indicateurChoix={indicateurChoix} maskTemp={maskTemp}
                        />;
                    } else {
                        return null;
                    }
                })
        }
    </React.Fragment>;
}