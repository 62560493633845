import Cookies                                                           from "js-cookie";
import React, { useEffect, useRef, useState }                            from "react";
import { CarteType, PopUpMaj }                                           from "../../../types/components/ville/CarteType";
import { Expedition }                                                    from "../../../types/components/ville/Expedition";
import { InfoCamping }                                                   from "../../../types/components/ville/InfoCamping";
import { ParamCarte }                                                    from "../../../types/components/ville/ParamCarte";
import { SelectedArray }                                                 from "../../../types/components/ville/SelectedArray";
import { ZoneMaj }                                                       from "../../../types/components/ville/Zone";
import { CarteApi }                                                      from "../../../services/api/CarteApi";
import { ExpeditionApi }                                                 from "../../../services/api/ExpeditionApi";
import colormap                                                          from "colormap";
import { Bordure, BordureCase }                                          from "./Bordure";
import SvgCase                                                           from "./SvgCase";
import { TraceExpedition, TraceExpeditionHordes, TraceExpeditionManuel } from "./TraceExpedition";
import DetailCase                                                        from "./DetailCase";
import { Helmet }                                                        from "react-helmet-async";
import MenuCarte                                                         from "./MenuCarte";
import PopUpMajManuel                                                    from "./PopUpMajManuel";
import { useGeneralContext }                                             from "../../../types/Context/GeneralContext";
import { Status_error, Status_success, usePopUp }                        from "../../../types/Context/PopUpContext";
import { CarteContext }                                                  from "../../../types/Context/CarteContext";
import { CustomPagination }                                              from "../../../components/generality/Pagination";
import Modal                                                             from "react-bootstrap/Modal";
import Camping                                                           from "./Camping";
import { UserDTO }                                                       from "../../../types/models/user.dto";
import { ConsigneExpeditionDTO }                                         from "../../../types/models/consigneExpedition.dto";
import { VilleDTO }                                                      from "../../../types/models/ville.dto";
import { ZoneMapDTO }                                                    from "../../../types/models/zoneMap.dto";
import { useTranslation }                                                from "react-i18next";

const generateCSSUser = (user: UserDTO) => {
    return `
		.Bat{color:${user.user_personnalisation.couleur_select_bat};}
		.select_bat { background-color: ${user.user_personnalisation.couleur_select_bat}; }
		.Cit{color:${user.user_personnalisation.couleur_select_cit};}
		.select_cit { background-color: ${user.user_personnalisation.couleur_select_cit}; }
		.Obj{color:${user.user_personnalisation.couleur_select_obj};}
		.select_obj { background-color: ${user.user_personnalisation.couleur_select_obj}; }
		.vueAuj { color: ${user.user_personnalisation.couleur_vue_auj}; }
		.vue24 { color: ${user.user_personnalisation.couleur_vue24}; }
		.vue48 { color: ${user.user_personnalisation.couleur_vue48}; }
		.selectedExpe { background-color: ${user.user_personnalisation.color_sel_exp}; }
		.myExpe { background-color: ${user.user_personnalisation.color_my_exp}; }
		.myFlag { color: ${user.user_personnalisation.color_flag}; }
		.myFlagFinis { color: ${user.user_personnalisation.color_flag_finish}; }
		.visuCase { color: ${user.user_personnalisation.couleur_sel_case_maj}; }
		.zone-danger0 { background-color: ${user.user_personnalisation.couleur_danger0}; }
		.zone-danger1 { background-color: ${user.user_personnalisation.couleur_danger1}; }
		.zone-danger2 { background-color: ${user.user_personnalisation.couleur_danger2}; }
		.zone-danger3 { background-color: ${user.user_personnalisation.couleur_danger3}; }
		.background_carte_color { background-color: ${user.user_personnalisation.couleur_carte}; }
		.caseVille { background-color: ${user.user_personnalisation.color_town}; }
		.zone-NonExplo { background-color: ${user.user_personnalisation.color_non_vu}; }
		`;
};
const generateCSSVille = (ville: VilleDTO, optionPerso: boolean, userOption: UserDTO) => {
    let variableTaille: number;
    let variableTailleSpe: number;
    const tailleVille = optionPerso ? 24 : ville.height;
    
    if (userOption.user_personnalisation.resizabled) {
        let tailleChoisi = userOption.user_personnalisation.width_case ?? 43;
        // On vérifie que la taille ne dépasse pas 1144px
        
        if (tailleVille > 15) {
            if (tailleVille * userOption.user_personnalisation.width_case > 1144) {
                tailleChoisi = Math.ceil(1144 / tailleVille);
            }
            document.documentElement.style.setProperty("--tailleGd", `${tailleChoisi}px`);
            variableTailleSpe = Math.ceil(tailleChoisi / 4);
            document.documentElement.style.setProperty("--tailleGdSpe", `${variableTailleSpe}px`);
        } else {
            if (tailleVille * userOption.user_personnalisation.width_case > 1144) {
                tailleChoisi = Math.ceil(1144 / tailleVille);
            } else {
                tailleChoisi = Math.ceil(tailleChoisi * 1.35);
            }
            document.documentElement.style.setProperty("--taillePt", `${tailleChoisi}px`);
            variableTailleSpe = Math.ceil(tailleChoisi / 5);
            document.documentElement.style.setProperty("--tailleGdSpe", `${variableTailleSpe}px`);
        }
    } else {
        if (tailleVille > 15) {
            variableTaille = Math.ceil(1144 / tailleVille);
            document.documentElement.style.setProperty("--tailleGd", `${variableTaille}px`);
            variableTailleSpe = Math.ceil((10 / tailleVille) * 27);
            document.documentElement.style.setProperty("--tailleGdSpe", `${variableTailleSpe}px`);
        } else {
            variableTaille = Math.ceil(780 / tailleVille);
            document.documentElement.style.setProperty("--taillePt", `${variableTaille}px`);
            variableTailleSpe = Math.ceil((12 / tailleVille) * 15);
            document.documentElement.style.setProperty("--tailleGdSpe", `${variableTailleSpe}px`);
        }
    }
    
    
    // calcul de la taille du picto home
    const taillePicto = Math.ceil(variableTaille / 2);
    
    return `
   .c${ville.height} {width : ${variableTaille * ville.height + 50}px;}
   .picto_home_ville {font-size : ${taillePicto}px; color: ${userOption.user_personnalisation.color_city};}
   `;
};
const initialDim = {
    height: 0,
    width : 0,
    top   : 0,
    left  : 0,
};
const selectOptionPerso = {
    obj : [2],
    bat : ["3_1", "5_3", "0_4"],
    cit : [1, 4],
    expe: [],
};
const paramCartePerso = (carteOptionPerso_alter: boolean, carteOptionPerso_estim: boolean) => {
    return {
        danger     : true,
        distance   : true,
        distance_pa: true,
        zonage     : true,
        scrutateur : true,
        zombie     : true,
        epuise     : true,
        objetSol   : true,
        objetMarq  : true,
        citoyen    : true,
        indicVisite: true,
        carteAlter : carteOptionPerso_alter,
        carteScrut : false,
        estimZombie: carteOptionPerso_estim,
        balisage   : false,
        arrKm      : [2, 4, 6, 8],
        arrPa      : [3, 5],
        arrZonage  : [7],
        arrAlter   : [112],
    };
};
const paramCarteInit = {
    danger     : true,
    distance   : true,
    distance_pa: false,
    zonage     : false,
    scrutateur : true,
    zombie     : true,
    epuise     : true,
    objetSol   : true,
    objetMarq  : true,
    citoyen    : true,
    indicVisite: true,
    carteAlter : false,
    carteScrut : false,
    estimZombie: false,
    balisage   : false,
    arrKm      : [2, 10, 11, 17],
    arrPa      : [3, 6, 9, 12],
    arrZonage  : [7, 19],
    arrAlter   : [112, 113, 114, 116, 117, 118],
};
const selectCarteInit = { obj: [], bat: [], cit: [], expe: [] };

export default function Carte(props: {
    carte: CarteType;
    popUpMaj?: PopUpMaj;
    onRefresh?: () => void;
}) {
    const { t } = useTranslation();
    const paramCarteCookieOld = Cookies.get("paramCarteV6");
    const selectedCarteCookieOld = Cookies.get("selectionCarte");
    
    if (paramCarteCookieOld) {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() - 1);
        
        Cookies.set("paramCarteV5", JSON.stringify([]), {
            expires: expirationDate,
            path   : "/",
            secure : true,
        });
    }
    if (selectedCarteCookieOld) {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() - 1);
        
        Cookies.set("selectionCarte", JSON.stringify([]), {
            expires: expirationDate,
            path   : "/",
            secure : true,
        });
    }
    
    const paramCarteCookie = Cookies.get("paramCarteV7");
    const selectedCarteCookie = Cookies.get("selectionCarteV1");
    const selectedCarteCookieMapId = Cookies.get("selectionCarteMapId");
    const modeExpeCarteCookie = Cookies.get("mode_expe");
    const modeCollab = Cookies.get("mode_collab");
    const modeBiblio = Cookies.get("mode_biblio");
    const modePerso = Cookies.get("mode_perso");
    
    
    const myRef = useRef<HTMLDivElement>(null);
    const tableRef = useRef<HTMLTableElement>(null);
    
    const same_map: boolean = selectedCarteCookieMapId ? JSON.parse(selectedCarteCookieMapId) === props.carte.ville.map_id : false;
    const [infoCase, setInfoCase] = useState(null);
    const [dimensions, setDimensions] = useState(initialDim);
    const [showCampingCalculator, setShowCampingCalculator] = useState(false);
    const [showCaseMaj, setShowCaseMaj] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [idClick, setIdClick] = useState(null);
    const [popUpClick, setPopUpClick] = useState(false);
    const [carte, setCarte] = useState(props.carte);
    const [paramCarte, setParamCarte] = useState(props.carte.carteOptionPerso ? paramCartePerso(props.carte.carteOptionPerso_alter, props.carte.carteOptionPerso_estim) : (paramCarteCookie ? JSON.parse(paramCarteCookie) : paramCarteInit));
    const [selectedArray, setSelectedArray] = useState(props.carte.carteOptionPerso ? selectOptionPerso : (selectedCarteCookie ? (same_map ? JSON.parse(selectedCarteCookie) : selectCarteInit) : selectCarteInit));
    const [modeExpe, setModeExpe] = useState(props.carte.myVille || props.carte.isLead ? (modeExpeCarteCookie ? JSON.parse(modeExpeCarteCookie) : false) : false);
    const [carteTraceManuel, setCarteTraceManuel] = useState([]);
    const [expeManuel, setExpeManuel] = useState<Expedition>({
        id              : "",
        nom             : "",
        created_by      : props.carte.user,
        modify_by       : null,
        collab          : (carte.outilsExpe === null && modeCollab) ? JSON.parse(modeCollab) === true : false,
        personnel       : (carte.outilsExpe === null && modePerso) ? JSON.parse(modePerso) === true : false,
        jour            : props.carte.ville.jour,
        coordonnee      : [],
        couleur         : "#" + (Math.floor(Math.random() * 16777215).toString(16) + "000000").slice(0, 6),
        pa              : 0,
        biblio          : (carte.outilsExpe !== null && modeBiblio) ? JSON.parse(modeBiblio) === true : false,
        brouillon       : carte.outilsExpe !== null,
        trace_expedition: false,
    });
    const [zoneMaj, setZoneMaj] = useState(null);
    const [retourMajPopUp, setRetourMajPopUp] = useState(null);
    const [typeRetourMajPopup, setTypeRetourMajPopup] = useState(null);
    const [isOnRefresh, setIsOnRefresh] = useState(false);
    const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const [isSelCase, setIsSelCase] = useState(null);
    const [jourActuel, setJourActuel] = useState(props.carte.ville.jour);
    const { general } = useGeneralContext();
    const apiRef = new CarteApi(0);
    const expeditionApi = new ExpeditionApi(props.carte.ville.map_id);
    const [consigneExpes, setConsigneExpes] = useState<string[]>([]);
    
    const updateCarte = () => {
        const mapId = carte.ville.map_id;
        const userId = carte.user.id;
        
        // Vérifie si une API call est en cours, pour éviter les multiples appels
        if (isOnRefresh) {
            return;
        }
        setIsOnRefresh(true);
        
        apiRef.refresh({ mapId: mapId, userId: userId, outils: carte.outilsExpe !== null }).then((result) => {
            setCarte(result.data.zoneRetour);
        }).catch((error) => {
            setStatus(Status_error);
            setShowPop(true);
            setMessagePopUp(error.data.error);
        }).finally(() => {
            // Réinitialise l'état de l'API call
            setIsOnRefresh(false);
        });
    };
    
    const callUpdateZone = (zone: ZoneMapDTO) => {
        const x = zone.x;
        const y = zone.y;
        const mapId = props.carte.ville.map_id;
        
        apiRef.popUp({ x: x, y: y, mapId: mapId }).then((result) => {
            setShowCaseMaj(true);
            setZoneMaj(result.data.zoneMaj);
        }).catch((error) => {
            setStatus(Status_error);
            setMessagePopUp(error.data.error);
            setShowPop(true);
        });
    };
    
    const functionMouseMove = (id: string): void => {
        setCurrentId(id);
        const table = tableRef.current;
        const td = document.getElementById(id);
        const popUp = document.getElementById("case_" + id);
        if (!td || !table) {
            return;
        }
        
        
        const popUpWidth = 550;
        const popUpHeight = popUp?.clientHeight ?? 200;
        
        const tdRect = td.getBoundingClientRect();
        // Décalage de 20px à droite et en bas
        let left = tdRect.width + 20;
        let top = tdRect.height + 20;
        
        // Ajuster si les pop-up dépassent les bords de l'écran
        if (left + popUpWidth + tdRect.right > window.innerWidth) {
            left = -20 - popUpWidth; // Déplacer à gauche si débordement à droite
        }
        if (top + popUpHeight + tdRect.bottom > window.innerHeight) {
            top = -popUpHeight - 20; // Déplacer vers le haut si débordement en bas
        }
        
        
        setDimensions({ ...dimensions, top: top, left: left });
    };
    
    const handleMouseMove = (id: string): void => {
        if (!popUpClick && !props.carte.user.user_personnalisation.pop_up_click) {
            functionMouseMove(id);
        }
    };
    
    const handleClosePopUp = (event: { stopPropagation: () => void }, closePopUp: boolean | ((prevState: boolean) => boolean)) => {
        event.stopPropagation();
        setCurrentId(null); // Mettez une valeur de test pour voir si elle est appliquée
        setPopUpClick(closePopUp); // Inversez la valeur pour tester
    };
    
    const handleInfoCaseCamping = (info: InfoCamping) => {
        setInfoCase(info);
        setShowCampingCalculator(true);
    };
    
    const handleMouseOver = (id: string): void => {
        if (!popUpClick && !props.carte.user.user_personnalisation.pop_up_click) {
            functionMouseMove(id);
        }
    };
    
    const handleMouseClick = (id: string): void => {
        if (!showCampingCalculator) {
            if (isSelCase) {
                setIdClick(id);
            } else if (!modeExpe) {
                functionMouseMove(id);
                setCurrentId(id);
                setPopUpClick(true);
            } else {
                const coord = id.split("_");
                const x = parseInt(coord[0]);
                const y = parseInt(coord[1]);
                const coordonneeTrace = [...expeManuel.coordonnee];
                if (
                    coordonneeTrace.length === 0 ||
                    coordonneeTrace[coordonneeTrace.length - 1][0] !== x ||
                    coordonneeTrace[coordonneeTrace.length - 1][1] !== y
                ) {
                    let coordInter = [];
                    if (
                        coordonneeTrace.length > 0 &&
                        coordonneeTrace[coordonneeTrace.length - 1][0] !== x &&
                        coordonneeTrace[coordonneeTrace.length - 1][1] !== y
                    ) {
                        coordInter = [x, coordonneeTrace[coordonneeTrace.length - 1][1]];
                    }
                    if (coordInter.length !== 0) {
                        coordonneeTrace.push(coordInter);
                    }
                    
                    coordonneeTrace.push([x, y]);
                    const expeMod = { ...expeManuel };
                    expeMod.coordonnee = coordonneeTrace;
                    tracingExpe(expeMod);
                }
            }
        }
    };
    
    const handleMouseOut = () => {
        if (!popUpClick) {
            setCurrentId(null);
        }
    };
    
    const majScrut = () => {
        // Vérifie si une API call est en cours, pour éviter les multiples appels
        if (isApiCallInProgress) {
            return;
        }
        // Mettre à jour l'état pour indiquer que l'appel est en cours
        setIsApiCallInProgress(true);
        
        apiRef.majScrut({ mapId: props.carte.ville.map_id }).then((response) => {
            // si c'est ok, on informe que c'est ok et refresh de la carte, qq ms après
            if (response.data.codeRetour === 1) {
                setStatus(Status_success);
                setShowPop(true);
                setMessagePopUp(response.data.libRetour);
                window.setTimeout(() => {
                    setStatus(Status_success);
                    setShowPop(false);
                    setMessagePopUp("");
                }, 1500);
                updateCarte();
            }
        }).catch((error) => {
            setStatus(Status_error);
            setShowPop(true);
            setMessagePopUp(error.data.error);
        }).finally(() => {
            setIsApiCallInProgress(false);
        });
    };
    
    const recupExpe = (idExpe: string) => {
        expeditionApi.edit({ expe_id: idExpe }).then((result) => {
            if (result.codeRetour === 0) {
                const expirationDate = new Date();
                expirationDate.setDate(expirationDate.getDate() + 365);
                Cookies.set("mode_expe", JSON.stringify(true), {
                    expires: expirationDate,
                    path   : "/",
                    secure : true,
                });
                
                setModeExpe(true);
                const expedMod = {
                    ...expeManuel,
                    ...{
                        id              : idExpe,
                        nom             : result.zoneRetour.nom,
                        created_by      : result.zoneRetour.created_by,
                        modify_by       : general.user,
                        collab          : result.zoneRetour.collab,
                        personnel       : result.zoneRetour.personnel,
                        jour            : result.zoneRetour.jour,
                        pa              : result.zoneRetour.pa,
                        couleur         : result.zoneRetour.couleur,
                        coordonnee      : result.zoneRetour.coordonnee,
                        biblio          : result.zoneRetour.biblio,
                        brouillon       : result.zoneRetour.brouillon,
                        trace_expedition: result.zoneRetour.trace_expedition,
                    },
                };
                
                tracingExpe(expedMod);
            } else {
                setStatus(Status_error);
                setShowPop(true);
                setMessagePopUp(result.libRetour);
            }
        });
    };
    
    const suppExpe = (idExpe: string) => {
        expeditionApi.suptrace({ expe_id: idExpe }).then((result) => {
            if (result.codeRetour === 0) {
                setStatus(Status_success);
                setShowPop(true);
                setMessagePopUp(result.libRetour);
                
                if (result.codeRetour === 0) {
                    updateCarte();
                    window.setTimeout(() => {
                        setStatus(Status_success);
                        setShowPop(false);
                        setMessagePopUp("");
                    }, 1500);
                    
                }
            } else {
                setStatus(Status_error);
                setShowPop(true);
                setMessagePopUp(result.libRetour);
            }
        });
    };
    
    const saveExpe = (expedition: Expedition) => {
        const data = {
            mapId     : props.carte.ville.map_id,
            id        : expedition.id,
            nom       : expedition.nom,
            crea_id   : expedition.created_by.id,
            mod_id    : expedition.modify_by?.id ?? null,
            pa_expe   : expedition.pa,
            jour_expe : expedition.jour,
            collab    : expedition.collab,
            couleur   : expedition.couleur,
            coordonnee: expedition.coordonnee,
            personnel : expedition.personnel,
            biblio    : expedition.biblio,
            brouillon : expedition.brouillon,
        };
        expeditionApi.sauvegarde(data).then((result) => {
            const modeCollab = Cookies.get("mode_collab");
            
            if (result.codeRetour === 0) {
                setStatus(Status_success);
                setShowPop(true);
                setMessagePopUp(result.libRetour);
                setCarteTraceManuel([]);
                const expeManuelMod = {
                    ...expeManuel,
                    ...{
                        id              : "",
                        nom             : "",
                        created_by      : props.carte.user,
                        modify_by       : null,
                        collab          : (carte.outilsExpe === null && modeCollab) ? JSON.parse(modeCollab) === true : false,
                        personnel       : (carte.outilsExpe === null && modePerso) ? JSON.parse(modePerso) === true : false,
                        jour            : props.carte.ville.jour,
                        coordonnee      : [],
                        couleur         : "#" + (Math.floor(Math.random() * 16777215).toString(16) + "000000").slice(0, 6),
                        pa              : 0,
                        biblio          : (carte.outilsExpe !== null && modeBiblio) ? JSON.parse(modeBiblio) === true : false,
                        brouillon       : carte.outilsExpe !== null,
                        trace_expedition: false,
                    },
                };
                setExpeManuel(expeManuelMod);
                updateExpedition(expeManuelMod, false);
            } else {
                setStatus(Status_error);
                setShowPop(true);
                setMessagePopUp(result.libRetour);
            }
            
            if (result.codeRetour === 0) {
                window.setTimeout(() => {
                    setStatus(Status_success);
                    setShowPop(false);
                    setMessagePopUp("");
                }, 1000);
                updateCarte();
            }
        });
    };
    
    const tracingExpe = (expeManuelMod: Expedition) => {
        const mapId = props.carte.ville.map_id;
        
        expeditionApi
            .tracing({ mapId: mapId, coord: expeManuelMod.coordonnee })
            .then((result) => {
                if (result.codeRetour === 0) {
                    setCarteTraceManuel(result.zoneRetour.trace);
                    setExpeManuel(({ ...expeManuelMod, ...{ pa: result.zoneRetour.pa } }));
                } else {
                    setStatus(Status_error);
                    setShowPop(true);
                    setMessagePopUp(result.libRetour);
                }
            });
    };
    
    const updateDimensions = () => {
        setDimensions({
            ...dimensions,
            width : window.innerWidth,
            height: window.innerHeight,
        });
    };
    
    const updateBatiment = (zoneMaj: ZoneMapDTO) => {
        const zones = { ...carte.ville.zones };
        zones[zoneMaj.y * 100 + zoneMaj.x] = zoneMaj;
        setCarte({
            ...carte,
            ...{ ville: { ...carte.ville, ...{ zones: zones } } },
        });
    };
    
    const updateParamCarte = (paramCarte: ParamCarte) => {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 365);
        setParamCarte(paramCarte);
        Cookies.set("paramCarteV7", JSON.stringify(paramCarte), {
            expires: expirationDate,
            path   : "/",
            secure : true,
        });
    };
    
    const updateModeExpe = (mode_expe: boolean) => {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 365);
        setModeExpe(mode_expe);
        Cookies.set("mode_expe", JSON.stringify(mode_expe), {
            expires: expirationDate,
            path   : "/",
            secure : true,
        });
    };
    
    const updateExpedition = (expedition: Expedition, retracage: boolean) => {
        if (retracage) {
            tracingExpe(expedition);
        } else {
            setExpeManuel(expedition);
        }
    };
    
    const updateSelectionCarte = (selectedArray: SelectedArray) => {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 365);
        setSelectedArray(selectedArray);
        Cookies.set("selectionCarteV1", JSON.stringify(selectedArray), {
            expires: expirationDate,
            path   : "/",
            secure : true,
        });
        Cookies.set(
            "selectionCarteMapId",
            JSON.stringify(props.carte.ville.map_id),
            {
                expires: expirationDate,
                path   : "/",
                secure : true,
            },
        );
    };
    
    const updateZoneMaj = (zoneMaj: ZoneMaj) => {
        const mapId = props.carte.ville.map_id;
        const userId = props.carte.user.id;
        
        apiRef.majCase({ mapid: mapId, userid: userId, zone_maj: zoneMaj }).then((result) => {
            setRetourMajPopUp(result.data.libRetour);
            setTypeRetourMajPopup(0);
            
            window.setTimeout(() => {
                setRetourMajPopUp(null);
                setShowCaseMaj(false);
                setPopUpClick(false);
                setTypeRetourMajPopup(null);
            }, 1000);
            updateCarte();
        }).catch((error) => {
            setRetourMajPopUp(error.data.error);
            setTypeRetourMajPopup(1);
        });
    };
    
    const applyFiltreObjet = (isFiltred: boolean, type_filtre: number, min: number, max: number) => {
        if (isFiltred) {
            const mapId = props.carte.ville.map_id;
            const userId = props.carte.user.id;
            apiRef.filtre_objet({ mapId: mapId, userId: userId, typeFiltre: type_filtre, min: min, max: max }).then((response) => {
                setCarte({
                    ...carte,
                    ...{
                        listCategorie     : response.data.listCategorie,
                        listItemsSolBroken: response.data.listItemsSolBroken,
                        ville             : { ...carte.ville, zones: response.data.zones },
                    },
                });
            }).catch((error) => {
                setRetourMajPopUp(error.data.error);
                setTypeRetourMajPopup(1);
            });
        } else {
            updateCarte();
        }
    };
    
    useEffect(() => {
        setCarte(props.carte);
    }, [props.carte]);
    
    useEffect(() => {
        setParamCarte({
            ...paramCarte,
            ...{ carteAlter: props.carte.carteOptionPerso_alter },
        });
    }, [props.carte.carteOptionPerso_alter]);
    
    useEffect(() => {
        setParamCarte({
            ...paramCarte,
            ...{ estimZombie: props.carte.carteOptionPerso_estim },
        });
    }, [props.carte.carteOptionPerso_estim]);
    
    useEffect(() => {
        
        
        // Code pour componentDidMount
        document.dispatchEvent(new Event("reactRendered"));
        // Ajoutez un écouteur d'événement pour le redimensionnement
        window.addEventListener("resize", updateDimensions);
        
        // Fonction de nettoyage pour componentWillUnmount
        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
    }, []);
    
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (myRef.current && !myRef.current.contains(event.target as Node)) {
                setShowCampingCalculator(false);
            }
        };
        
        // Ajoute un écouteur d'événement au clic à l'échelle de la page lors de l'ouverture du popup
        if (showCampingCalculator) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showCampingCalculator]);
    
    
    const updateEstimDay = (estim: number) => {
        const newCarte = { ...carte };
        newCarte.estim_day = estim;
        setCarte(newCarte);
    };
    const ville = carte.ville;
    const zones = ville.zones;
    const user = carte.user;
    const selectedCarte = selectedArray;
    
    const typeCarte = ville.weight < 25 && ville.weight > 10 ? "ptCarte" : "gdCarte";
    const typeVille = ville.weight > 15 || ville.weight < 10 ? "gd" : "pt";
    
    const tailleVille = carte.carteOptionPerso ? 24 : ville.height;
    let tailleCase: number;
    if (tailleVille > 15) {
        tailleCase = Math.ceil(1144 / tailleVille);
    } else {
        tailleCase = Math.ceil(780 / tailleVille);
    }
    
    const displayBlockPopUp = {
        display: "block",
        top    : dimensions.top,
        left   : dimensions.left,
    };
    
    const displayNonePopUp = {
        display: "none",
    };
    
    const displayBlock = "block";
    const displayNone = "none";
    
    const alpha = user.user_personnalisation.alpha_colormap / 100;
    
    const maxObjet = Math.min(carte.maxAlterAll, paramCarte.arrAlter.reduce((acc: number, id: string | number) => acc + carte.maxAlter[id], 0));
    //const maxObjetFinal = maxObjet < 10 ? 9 : maxObjet + 1;
    
    const colorap_carte = colormap({ colormap: user.user_personnalisation.colormap, nshades: 100, format: "rgbaString", alpha: alpha });
    
    const arrayCitoyen = [];
    if (selectedCarte.cit.length !== 0 && !ville.devast) {
        selectedCarte.cit.map((id: string | number) => {
            const citoyen = ville.list_citoyen_ville[id] ?? null;
            if (citoyen !== null) {
                arrayCitoyen.push(citoyen.x + "_" + citoyen.y);
            }
        });
    }
    
    const bordHaut = [];
    for (let i = 0; i < ville.weight; i++) {
        bordHaut.push(
            <Bordure classBordure={"bordHautBasCarte"} typeCarte={typeCarte} valeur={i - ville.pos_x} key={"bord_h_" + i} />,
        );
    }
    const bordBas = [];
    for (let i = 0; i < ville.weight; i++) {
        bordBas.push(<Bordure classBordure={"bordHautBasCarte"} typeCarte={typeCarte} valeur={i - ville.pos_x} key={"bord_b_" + i} />);
    }
    
    const borduresHaut = (
        <React.Fragment key={"frag_bord_haut"}>
            <tr className="ligneCarte" key={"bord_haut"}>
                <td className="fondNoir coinCarte"></td>
                {bordHaut}
                <td className="fondNoir coinCarte"></td>
            </tr>
        </React.Fragment>
    );
    const borduresBas = (
        <React.Fragment key={"frag_bord_bas"}>
            <tr className="ligneCarte" key={"bord_bas"}>
                <td className="fondNoir coinCarte"></td>
                {bordBas}
                <td className="fondNoir coinCarte"></td>
            </tr>
        </React.Fragment>
    );
    
    const estimZombieSupp = Math.max(carte.ville.jour, carte.estim_day) - carte.ville.jour;
    
    const listeExpeJour = carte.listExp.filter((expedition) => {
        return (expedition.jour === jourActuel && expedition.trace_expedition === true) || expedition.trace_expedition === false;
    }).map((expedition) => {
        return expedition.id;
    });
    
    const lignes = [];
    for (let j = 0; j < ville.height; j++) {
        const cases = [];
        for (let i = 0; i < ville.weight; i++) {
            
            const zone = zones[j * 100 + i];
            const idTs = zone.x + "_" + zone.y;
            
            const caseVille = zone.vue === -2 && zone.x === ville.pos_x && zone.y === ville.pos_y;
            
            const displayPopup = currentId !== null && currentId === idTs ? displayBlockPopUp : displayNonePopUp;
            const display = currentId !== null && currentId === idTs ? displayBlock : displayNone;
            let valeursCommunes = [];
            if (zone.items !== null && selectedCarte.obj !== null && selectedCarte.obj.length !== 0) {
                valeursCommunes = zone.item_sol.filter((valeur) => selectedCarte.obj.includes(valeur));
            }
            let selectionBat = false;
            if (zone.bat !== null && selectedCarte.bat !== null && selectedCarte.bat.length !== 0) {
                selectionBat = selectedCarte.bat.includes(idTs);
            }
            
            let expeSelectionneGH = [];
            if (carte.carteExpe[zone.y * 100 + zone.x] !== undefined) {
                expeSelectionneGH = Object.entries(carte.carteExpe[zone.y * 100 + zone.x]).filter(([index]) => selectedCarte.expe.includes(index) && listeExpeJour.includes(index));
            }
            let expeSelectionneBiblio = [];
            if (carte.carteExpeBiblio[zone.y * 100 + zone.x] !== undefined) {
                expeSelectionneBiblio = Object.entries(carte.carteExpeBiblio[zone.y * 100 + zone.x]).filter(([index]) => selectedCarte.expe.includes(index));
            }
            let expeSelectionneBrouillon = [];
            if (carte.carteExpeBrouillon[zone.y * 100 + zone.x] !== undefined) {
                expeSelectionneBrouillon = Object.entries(carte.carteExpeBrouillon[zone.y * 100 + zone.x]).filter(([index]) => selectedCarte.expe.includes(index));
            }
            let expeSelectionneMH = [];
            if (carte.carteExpeHordes[zone.y * 100 + zone.x] !== undefined) {
                expeSelectionneMH = Object.entries(carte.carteExpeHordes[zone.y * 100 + zone.x]).filter(([index]) => {
                    return selectedCarte.expe.includes(parseInt(index, 10));
                });
            }
            
            const selectionObjet = valeursCommunes.length !== 0;
            let selectionCit = false;
            if (ville.list_citoyen_vie[zone.y * 100 + zone.x] !== undefined) {
                const arrayTmp = ville.list_citoyen_vie[zone.y * 100 + zone.x].filter((valeur) => selectedCarte.cit.includes(valeur.citoyen.id));
                selectionCit = arrayTmp.length !== 0;
            }
            
            const conditions = [selectionObjet, selectionBat, selectionCit];
            const nombreDeValeursTrue = conditions.reduce((total, condition) => {
                if (condition) {
                    return total + 1;
                } else {
                    return total;
                }
            }, 0);
            
            const objetCarteAlter = paramCarte.arrAlter.reduce((acc: number, id: string | number) => acc + zone.carte_alter[id], 0);
            
            let consigne_case: ConsigneExpeditionDTO[] = null;
            if (zone.consigne_expeditions !== null) {
                consigne_case = zone.consigne_expeditions.filter((consigne) => carte.listExpInscrit.includes(consigne.expedition_part.trace?.id));
            }
            
            cases.push(
                <React.Fragment key={"casemap_" + idTs}>
                    <td id={idTs} className={"caseCarte " + typeCarte} data-x={zone.x} data-y={zone.y} onMouseOver={() => handleMouseOver(idTs)}
                        onMouseMove={() => handleMouseMove(idTs)} onClick={() => handleMouseClick(idTs)} key={"case_" + idTs}>
                        <div className={"caseCarteInfo"}>
                            {caseVille && <>
                                <div className="caseVille">
                                    <div className={"picto_home_ville"}><i className="fa-solid fa-house-flag"></i></div>
                                </div>
                                {consigne_case !== null && consigne_case.map((consigne, index) => {
                                    if (!consigne.fait) {
                                        return <SvgCase classPrincipale={"consigneExpe myFlag"} typeCarte={typeCarte} svg={"divers"} idSvg={"flagConsigne"} key={"flag_" + idTs + index} />;
                                    }
                                    if (consigne.fait) {
                                        return <SvgCase classPrincipale={"consigneExpe myFlagFinis"} typeCarte={typeCarte} svg={"divers"} idSvg={"flagConsigne"} key={"flag_" + idTs + index} />;
                                    }
                                })}
                            </>}
                            {!caseVille && (<React.Fragment key={"caseHV_" + idTs}>
                                    {zone.vue === -1 && (<div className={"zone-NonExplo " + typeCarte} key={"danger_" + idTs}></div>)}
                                    {paramCarte.danger && (!paramCarte.carteAlter && !paramCarte.carteScrut) && zone.vue === 1 && zone.danger != null && (<div className={"danger zone-danger" + zone.danger + " " + typeCarte} key={"danger_" + idTs}></div>)}
                                    <SvgCase classPrincipale={"quadrillage"} typeCarte={typeCarte} svg={"bord"} idSvg={"bordCase"} style={{ display: "block" }} key={"quadrillage_" + idTs} />
                                    {zone.vue !== -1 && zone.dried && paramCarte.epuise && !paramCarte.carteAlter && (<SvgCase classPrincipale={"epuise zone-epuise"} typeCarte={typeCarte} svg={"divers"} idSvg={"hachureDroiteGauche"} style={{ color: user.user_personnalisation.couleur_epuise }} key={"epuise_" + idTs} />)}
                                    {zone.plan !== null && !zone.camped && (<SvgCase classPrincipale={"camp " + zone.plan} typeCarte={typeCarte} svg={"divers"} idSvg={"plan"} key={"camp_" + idTs} />)}
                                    {zone.bat !== null && (<SvgCase classPrincipale={"bat"} typeCarte={typeCarte} svg={"divers"} style={{ color: user.user_personnalisation.couleur_bat }} idSvg={zone.status_bat} key={"bat_" + idTs} />)}
                                    {zone.lvl_balisage !== null && zone.vue !== -1 && paramCarte.balisage && (<SvgCase classPrincipale={"balisage"} typeCarte={typeCarte} svg={"divers"} idSvg={zone.lvl_balisage + "z"} style={{ color: user.user_personnalisation.color_zombie }} key={"balisage_" + idTs} />)}
                                    {zone.zombie >= 0 && zone.vue !== -1 && paramCarte.zombie && !paramCarte.estimZombie && !paramCarte.balisage && (
                                        <SvgCase classPrincipale={"zombReel zombie"} typeCarte={typeCarte} svg={"divers"} idSvg={user.user_personnalisation.zombie_discret && zone.zombie === 0 ? "-z" : zone.zombie + "z"} style={{ color: user.user_personnalisation.color_zombie }} key={"zomReel_" + idTs} />)}
                                    {zone.zombie_min !== null && zone.zombie_min >= 0 && zone.vue === -1 && paramCarte.zombie && !paramCarte.estimZombie &&
                                        (<div className={"zombRange"}>
                                                <SvgCase classPrincipale={"zombReel zombie"} typeCarte={typeCarte} svg={"divers"} idSvg={zone.zombie_min + "z"}
                                                         style={{ color: user.user_personnalisation.color_zombie }} key={"zomFourchette_" + idTs + "_" + zone.zombie_min} />
                                                <SvgCase classPrincipale={"zombReel zombie"} typeCarte={typeCarte} svg={"divers"} idSvg={"-z"} style={{
                                                    color: user.user_personnalisation.color_zombie,
                                                    left : `${Math.round(tailleCase / 2) - (zone.zombie_min < 10 ? 9 : 6)}px`,
                                                }} key={"zomFourchette_" + idTs} />
                                                <SvgCase classPrincipale={"zombReel zombie"} typeCarte={typeCarte} svg={"divers"} idSvg={zone.zombie_max + "z"} style={{
                                                    color: user.user_personnalisation.color_zombie,
                                                    left : `${Math.round(tailleCase / 2)}px`,
                                                }} key={"zomFourchette_" + idTs + "_" + zone.zombie_max} />
                                            </div>
                                        )}
                                    {zone.zombie_estim !== null && zone.zombie_estim >= 0 && zone.vue !== -1 && paramCarte.estimZombie && (
                                        <SvgCase classPrincipale={"zombEstim zombie"} typeCarte={typeCarte} svg={"divers"} style={{ color: user.user_personnalisation.color_estimation_zombie }} idSvg={zone.zombie_estim + estimZombieSupp + "z"} key={"zomEstim_" + idTs} />)}
                                    {zone.items.length > 0 && paramCarte.objetSol && !paramCarte.carteAlter && !paramCarte.carteScrut && !zone.marqueur_only && <SvgCase classPrincipale={"objetSol"} typeCarte={typeCarte} svg={"divers"} idSvg={"presObjet"} key={"objetSol_" + idTs} />}
                                    {zone.items.length > 0 && paramCarte.objetMarq && !paramCarte.carteAlter && !paramCarte.carteScrut && zone.marqueur_only && <SvgCase classPrincipale={"objetSol"} typeCarte={typeCarte} svg={"divers"} idSvg={"presMarqueur"} key={"objetSol_" + idTs} />}
                                    {zone.bord_km !== null && paramCarte.distance && paramCarte.arrKm.includes(zone.km) && (<SvgCase classPrincipale={"km_" + zone.km + " distance"} typeCarte={typeCarte} style={{ color: user.user_personnalisation.couleur_km }} svg={"bord"} idSvg={"b_" + zone.bord_km} key={"bord_km_" + idTs} />)}
                                    {zone.bord_scrut !== null && paramCarte.scrutateur && (<SvgCase classPrincipale={"scrut"} typeCarte={typeCarte} svg={"bord"} style={{ color: user.user_personnalisation.couleur_scrut }} idSvg={"b_" + zone.bord_scrut} key={"bord_scrut_" + idTs} />)}
                                    {zone.bord_pa !== null && paramCarte.distance_pa && paramCarte.arrPa.includes(zone.pa) && (<SvgCase classPrincipale={"pa_" + zone.pa + " distance_pa"} typeCarte={typeCarte} style={{ color: user.user_personnalisation.couleur_p_a }} svg={"bord"} idSvg={"b_" + zone.bord_pa} key={"bord_pa_" + idTs} />)}
                                    {zone.bord_zonage !== null && paramCarte.zonage && paramCarte.arrZonage.includes(zone.zone) && (
                                        <SvgCase classPrincipale={"zonage_" + zone.zone + " zonage"} typeCarte={typeCarte} style={{ color: user.user_personnalisation.couleur_zone }} svg={"bord"} idSvg={"b_" + zone.bord_zonage} key={"bord_zonage_" + idTs} />)}
                                    {zone.items.length > 0 && objetCarteAlter > 0 && paramCarte.carteAlter && !paramCarte.carteScrut && (<div className={"carteAlter " + typeCarte} style={{ backgroundColor: colorap_carte[Math.round((objetCarteAlter / maxObjet + Number.EPSILON) * 100) - 1] }}>
                                        <div className={"divCarteAlter"}><span className={"itemDechargeable"}>{objetCarteAlter}</span></div>
                                    </div>)}
                                    {zone.items.length > 0 && zone.carte_scrut > 0 && !paramCarte.carteAlter && paramCarte.carteScrut && (<div className={"carteScrut " + typeCarte} style={{ backgroundColor: colorap_carte[Math.round((zone.carte_scrut / carte.maxScrut + Number.EPSILON) * 100) - 1] }}>
                                        <div className={"divCarteScrut"}><span className={"itemRegenerer"}>{zone.carte_scrut}</span></div>
                                    </div>)}
                                    {ville.ma_position !== null && ville.ma_position === (zone.x + zone.y * 100) && (<SvgCase classPrincipale={"posJoueur"} typeCarte={typeCarte} svg={"divers"} style={{ color: user.user_personnalisation.couleur_pos }} idSvg={"posJoueur"} key={"maPos_" + idTs} />)}
                                    {zone.indic_visite !== null && paramCarte.indicVisite && !paramCarte.carteAlter && !paramCarte.carteScrut && (<SvgCase classPrincipale={"indicVisit " + zone.indic_visite} typeCarte={typeCarte} svg={"divers"} idSvg={"indicateurVue"} key={"indic_" + idTs} />)}
                                    {ville.list_citoyen_vie[zone.y * 100 + zone.x] !== undefined && paramCarte.citoyen && (<div className={"citoyensVilleDiv " + typeCarte}>{user.user_personnalisation.affichage_nb_citoyen && (<span className={typeVille + "CitoyensVilleSpan"}>{ville.list_citoyen_vie[zone.y * 100 + zone.x].length}</span>)}
                                        <SvgCase classPrincipale={"citoyensVille"} typeCarte={typeVille + "CitoyensVille"} svg={"icone"} idSvg={"h_human"} key={"citVille_" + idTs} />
                                    </div>)}
                                    {nombreDeValeursTrue === 1 && (<div className={"selectionCase"}><BordureCase typeCarte={typeCarte} idBordure={"bordSelect"} classBordure={"select_1 selected_carte"} classOption={selectionBat ? "Bat" : selectionCit ? "Cit" : "Obj"} /></div>)}
                                    {nombreDeValeursTrue === 2 && (
                                        <div className={"selectionCase"}><BordureCase typeCarte={typeCarte} idBordure={"bordSelect2-1"} classBordure={"select_2 selected_carte"} classOption={selectionBat ? "Bat" : "Cit"} /><BordureCase typeCarte={typeCarte} idBordure={"bordSelect2-2"} classBordure={"select_2 selected_carte"}
                                                                                                                                                                                                                                           classOption={selectionObjet ? "Obj" : "Cit"} /></div>)}
                                    {nombreDeValeursTrue === 3 && (<div className={"selectionCase"}><BordureCase typeCarte={typeCarte} idBordure={"bordSelect3-1"} classBordure={"select_3 selected_carte"} classOption={"Bat"} />
                                        <BordureCase typeCarte={typeCarte} idBordure={"bordSelect3-2"} classBordure={"select_3 selected_carte"} classOption={"Cit"} />
                                        <BordureCase typeCarte={typeCarte} idBordure={"bordSelect3-3"} classBordure={"select_3 selected_carte"} classOption={"Obj"} />
                                    </div>)}
                                    {zone.items.length > 0 && valeursCommunes.length > 0 && (<div className={"listingItemsSelection"} key={"objetCase_" + idTs}>
                                            {valeursCommunes.length === 1 && (
                                                <SvgCase classPrincipale={"miniIcone Spe selected_objet"} typeCarte={typeCarte + "Spe"} svg={"icone"}
                                                         idSvg={zone.item_sol_icone[valeursCommunes[0]]} />)}
                                            {valeursCommunes.length > 1 &&
                                                (<SvgCase classPrincipale={"miniIconeMulti selected_objet"} typeCarte={typeCarte + "Spe"} svg={"icone"} idSvg={"tag_4"} />)}
                                        </div>
                                    )}
                                    {consigne_case !== null && consigne_case.map((consigne, index) => {
                                        if (!consigne.fait) {
                                            return <SvgCase classPrincipale={"consigneExpe myFlag"} typeCarte={typeCarte} svg={"divers"} idSvg={"flagConsigne"} key={"flag_" + idTs + index} />;
                                        }
                                        if (consigne.fait) {
                                            return <SvgCase classPrincipale={"consigneExpe myFlagFinis"} typeCarte={typeCarte} svg={"divers"} idSvg={"flagConsigne"} key={"flag_" + idTs + index} />;
                                        }
                                    })}
                                    {consigneExpes.length > 0 && consigneExpes.includes(idTs) && <SvgCase classPrincipale={"consigneExpe myFlag"} typeCarte={typeCarte} svg={"divers"} idSvg={"flagConsigne"} key={"flag_" + idTs} />}
                                </React.Fragment>
                            )}
                            
                            <BordureCase typeCarte={typeCarte} classBordure={"visuCase"} idBordure={"bordSelect"} display={display} />
                            {expeSelectionneGH.length > 0 && (<div className={"expeditionVille " + typeCarte}>
                                    {expeSelectionneGH.length > 0 && (<TraceExpedition typeCarte={typeCarte} carte={carte} zone={zone} key={"expe_" + idTs}
                                                                                       listExpe={carte.carteExpe[zone.y * 100 + zone.x]}
                                                                                       selectedArray={selectedArray.expe} />)}
                                </div>
                            )}
                            {expeSelectionneBiblio.length > 0 && (<div className={"expeditionVille " + typeCarte}>
                                    {expeSelectionneBiblio.length > 0 && (<TraceExpedition typeCarte={typeCarte} carte={carte} zone={zone} key={"expe_" + idTs}
                                                                                           listExpe={carte.carteExpeBiblio[zone.y * 100 + zone.x]}
                                                                                           selectedArray={selectedArray.expe} />)}
                                </div>
                            )}
                            {expeSelectionneBrouillon.length > 0 && (<div className={"expeditionVille " + typeCarte}>
                                    {expeSelectionneBrouillon.length > 0 && (<TraceExpedition typeCarte={typeCarte} carte={carte} zone={zone} key={"expe_" + idTs}
                                                                                              listExpe={carte.carteExpeBrouillon[zone.y * 100 + zone.x]}
                                                                                              selectedArray={selectedArray.expe} />)}
                                </div>
                            )}
                            {expeSelectionneMH.length > 0 && (
                                <div className={"expeditionVille " + typeCarte}>
                                    {expeSelectionneMH.length > 0 && (<TraceExpeditionHordes typeCarte={typeCarte} carte={carte} zone={zone} key={"expe_" + idTs}
                                                                                             listExpe={carte.carteExpeHordes[zone.y * 100 + zone.x]}
                                                                                             selectedArray={selectedArray.expe} />
                                    )}
                                </div>
                            )}
                            {modeExpe && carteTraceManuel.length > 0 && carteTraceManuel[zone.y][zone.x] !== null &&
                                (<div className={"expeditionVille " + typeCarte}>
                                        <TraceExpeditionManuel typeCarte={typeCarte} carte={carte} zone={zone} key={"expe_man_" + idTs}
                                                               carteExpe={carteTraceManuel[zone.y][zone.x]} couleur={expeManuel.couleur} />
                                    </div>
                                )}
                        </div>
                        {(currentId !== null && currentId === idTs) && <DetailCase
                            typeCarte={typeCarte}
                            carte={carte}
                            zone={zone}
                            onSendInfoCamping={handleInfoCaseCamping}
                            closePopUp={(event, closePop) => handleClosePopUp(event, closePop)}
                            display={displayPopup}
                            onSendBatiment={updateBatiment}
                            onUpdateZone={callUpdateZone}
                            onRefresh={props.onRefresh}
                        />
                        }
                    </td>
                </React.Fragment>,
            );
        }
        
        lignes.push(
            <React.Fragment key={"ligne_" + j}>
                <tr className="ligneCarte" key={"lign_" + j}>
                    <Bordure classBordure={"bordGaucheDroiteCarte"} typeCarte={typeCarte} valeur={ville.pos_y - j} key={"bord_g_" + j} />
                    {cases}
                    <Bordure classBordure={"bordGaucheDroiteCarte"} typeCarte={typeCarte} valeur={ville.pos_y - j} key={"bord_d_" + j} />
                </tr>
            </React.Fragment>,
        );
    }
    
    return (
        <>
            <CarteContext.Provider value={{ carte, setCarte, idClick, setIdClick, isSelCase, setIsSelCase, jourActuel, setJourActuel, consigneExpes, setConsigneExpes }}>
                <Helmet>
                    <style>{generateCSSUser(user)}</style>
                    <style>{generateCSSVille(ville, carte.carteOptionPerso, user)}</style>
                </Helmet>
                {carte.outilsExpe && <div id={"zone_choix_jour"}>
                    <h2>{t("Jour", { ns: "ville" })}</h2>
                    <CustomPagination jour={jourActuel} jour_max={carte.ville.jour + 3} onChoixJour={selectedDay => setJourActuel(selectedDay)} />
                </div>}
                {!carte.carteOptionPerso && (
                    <MenuCarte
                        carte={carte}
                        paramCarte={paramCarte}
                        mode_expe={modeExpe}
                        selectedCarteArray={selectedArray}
                        expeManuel={expeManuel}
                        onUpdateParamCarte={updateParamCarte}
                        onUpdateSelectionCarte={updateSelectionCarte}
                        onUpdateModeExpe={updateModeExpe}
                        onUpdateExpedition={updateExpedition}
                        onEditExpedition={recupExpe}
                        onMajScrut={majScrut}
                        onRefresh={updateCarte}
                        onApplyFiltre={applyFiltreObjet}
                        onSaveExpedition={saveExpe}
                        onSuppExpedition={suppExpe}
                        isApiCallInProgress={isApiCallInProgress}
                        onMajEstimDay={updateEstimDay}
                        onShowCase={(idCase) => {
                            setPopUpClick(true);
                            setCurrentId(idCase);
                        }}
                        key={"carte_menu"}
                    />
                )}
                <div id={"zoneCarte"} className={"c" + ville.weight} key={"carte_div"}>
                    <div className={user.user_personnalisation.carte_textured || user.theme === "vintage" ? "background_carte_sable" : "background_carte_lisse"}>
                        <div className={"background_carte_color"}>
                            <table onMouseLeave={handleMouseOut} ref={tableRef}>
                                <tbody>
                                {borduresHaut}
                                {lignes}
                                {borduresBas}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    <Modal show={showCampingCalculator} onHide={() => setShowCampingCalculator(false)} centered key={"modal_camping"}>
                        {showCampingCalculator && carte.campingActif && (
                            <div id="infoCamping" ref={myRef}>
                                <Camping infoCase={infoCase} />
                                <button onClick={() => setShowCampingCalculator(false)} type={"button"}>
                                    {t("Fermer calcul camping", { ns: "ville" })}
                                </button>
                            </div>
                        )}
                    </Modal>
                    <Modal show={showCaseMaj} onHide={() => setShowCaseMaj(false)} centered key={"modal_maj_manuel"}>
                        {showCaseMaj && (
                            <PopUpMajManuel
                                popUpMaj={props.popUpMaj}
                                zoneMaj={zoneMaj}
                                retourMajPopUp={retourMajPopUp}
                                typeRetourMajPopup={typeRetourMajPopup}
                                onCancel={() => {
                                    setShowCaseMaj(false);
                                    setRetourMajPopUp(null);
                                    setTypeRetourMajPopup(null);
                                }}
                                onSaveCase={updateZoneMaj}
                            />
                        )}
                    </Modal>
                </div>
            </CarteContext.Provider>
        </>
    );
}
